#home {
	
	#gl-stuff {
		position: fixed;
		width: 100%;
		height: 100vh;
		left: 0;
		top: 0;
		z-index: -1;
	}

	.scroller {
		opacity: 0;
	}

	section {
		position: relative;
	    width: 100vw;
	    min-height: 60vh;
	    mix-blend-mode: exclusion;
	    z-index: 2;
	}

	.intro {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
		height: 100vh;

		&__inner {
			width: 100%;
			position: relative;
			margin-left: 0.5vw;

			h1.intro-title {
				position: relative;
				font-size: 27vw;
				text-transform: uppercase;
				font-weight: bold;
				line-height: 0.90;
				overflow: hidden;
				margin: 0;

				@media (min-width: 768px) {
					font-size: 18vw;
				}

				span.small {
					font-size: 8vw;
				}
			}
		}
	}

	.hello {
		display: flex;
		align-items: center;
		height: 110vh;

		&__inner {
			max-width: 90vw;
			margin-left: 5vw;
			margin-top: 20vh;

			@media (min-width: 768px) {
				max-width: 70vw;
			}
		}

		&__text {
			font-size: 7vw;

			@media (min-width: 768px) {
				font-size: 2.5vw;
			}

			span {
				font-weight: bold;
				display: block;
				margin-bottom: 8vh;
			}
		}
	}

	.work {
		margin-bottom: 25vh;
		
		&__items {
			max-width: 90vw;
			margin: 6rem auto;
			

			@media (min-width: 768px) {
				max-width: 60vw;
				position: relative;

				.distort {
					position: absolute;
					pointer-events: none;
					will-change: transform;
				}

				.distort__img {
					opacity: 0;
					//mix-blend-mode: exclusion;
				}
			}
		}

		&__item {
			position: relative;
			width: 100%;
			display: block;
			text-transform: uppercase;

			a {
				display: block;
				font-size: 6vw;
				padding: 4rem 0;
				overflow: hidden;
				transition: all 0.3s ease-in-out;

				@media (min-width: 768px) {
					font-size: 3vw;
				}

				&:hover {
					transform: skewX(-25deg);
				}
			}

			.line {
				width: 100%;
				height: 1px;
				background: #fff;
				position: absolute;
				bottom: 0;
				transform: scaleX(0);
				transform-origin: left;
			}
		}
	}

	.techno {

		h3 {
			display: block;
			color: #191919;
			padding-top: 30vh;
		}

		.marquee {
	        padding-top: 10vh;
		    padding-bottom: 35vh;
		    position: relative;
		    overflow-x: hidden;
		    

	        &__inner {
	          width: fit-content;
	          display: flex;
	          position: relative;
	          white-space: nowrap;
	          
	          span {
	            font-size: 6vh;
	            font-weight: bold;
	            line-height: 1;
	            padding: 0 5vw;
	            text-transform: uppercase;
	            margin-right: 5vw;
	            color: #010101;

	            @media (min-width: 768px) {
	            	font-size: 10vw;
	            }
	          }
	        }

	        .first {
	          transform: translateX(50%);
	        }

	        .second {
	          transform: translateX(-80%);
	      	}
	    }
	}

	.about {
		position: relative;
		overflow: hidden;

		&__inner {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			justify-content: space-between;
			min-height: 80vh;

			@media (min-width: 768px) {
				min-height: 100vh;
			}
		}

		&__title {
			display: block;
			width: 100%;
			flex: 0 0 100%;
			margin-top: 10vh;
		}

		&__text {
			font-size: 5vw;
			padding: 0 3vw;
			margin-bottom: 8vw;

			@media (min-width: 768px) {
				max-width: 22vw;
				margin-left: 75vw;
				font-size: 1.2vw;
			}
		}

		&__img {
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: -1;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;

				@media (min-width: 768px) {
					//object-fit: none;
					object-position: left -10vh;
				}
			}
		}
	}
	
	.more {
		mix-blend-mode: soft-light;
		background-color: #000;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;

    	a.contact {
    		display: block;
			position: relative;
	    	white-space: nowrap;
    	}

    	.socials {
    		color: white;
    		display: flex;
    		width: 100%;
    		justify-content: center;
    		margin-top: 2vh;

			a {
				padding: 0 2vw;
			}
    	}

	}
}