.site-header {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 1rem;
    writing-mode: vertical-lr;
    mix-blend-mode: difference;

    @media (min-width: 768px) {
        padding: 3rem;
    }


	a {
	    display: inline-block;
	}

}